import * as Yup from 'yup';
import {locale} from '@innovago/ui';

export const getAnswers = (simulatorData, nif, broker, answers) => {
  const obj = {};
  obj.Policy = {};
  obj.Policy.BrokerNo = broker.broker_no;
  obj.Policy.Currency = 'EUR';
  obj.Policy.Product = simulatorData.ProductCode;
  obj.Policy.BrokerContact = {};
  obj.Policy.BrokerContact.ContactNo = broker.broker_no;

  const Policyholder = [];

  simulatorData.Policyholder.map(prop => {
    Policyholder.push({
      Question: prop.QuestionCode,
      Answer: answers[prop.InputId],
      InputId: prop.InputId,
    });
    return false;
  });
  obj.Policy.Policyholder = Policyholder;

  const PolicyRisks = [];

  simulatorData.Risks.map(risk => {
    const PolicyAnswers = [];
    risk.Questions.forEach(question => {
      PolicyAnswers.push({
        Question: question.QuestionCode,
        Answer: answers[question.InputId],
        InputId: question.InputId,
      });
    });

    const PolicyCovers = [];
    risk.Covers.map(cover => {
      const PolicyAnswers = [];
      cover.Questions.forEach(question => {
        PolicyAnswers.push({
          Question: question.QuestionCode,
          Answer: answers[question.InputId],
          InputId: question.InputId,
        });
      });
      const PolicyItems = [];
      cover.Items.forEach(item => {
        const PolicyAnswers = [];
        item.Questions.forEach(question => {
          PolicyAnswers.push({
            Question: question.QuestionCode,
            Answer: answers[question.InputId],
            InputId: question.InputId,
          });
        });
        PolicyItems.push({
          ItemCode: item.ItemCode,
          PolicyAnswers: PolicyAnswers,
        });
      });

      PolicyCovers.push({
        CoverCode: cover.CoverCode,
        PolicyAnswers: PolicyAnswers,
        PolicyItems: PolicyItems,
      });

      return true;
    });

    PolicyRisks.push({
      RiskCode: risk.RiskCode,
      PolicyAnswers: PolicyAnswers,
      PolicyCovers: PolicyCovers,
    });

    return false;
  });

  const generalQuestions = [];
  simulatorData.Questions.map(question => {
    generalQuestions.push({
      Question: question.QuestionCode,
      Answer: answers[question.InputId],
      InputId: question.InputId,
    });
    return false;
  });

  const Events = [
    {
      Event: 'QT ',
      Transaction: {
        PolicyAnswers: generalQuestions,
        PolicyRisks: PolicyRisks,
      },
    },
  ];
  obj.Policy.Events = Events;

  return obj;
};

const createYupSchema = (schema, config, visibility) => {
  const {InputId, Attribute, Validation = []} = config;
  let validator;
  switch (Attribute) {
    case 'Date':
      validator = Yup['mixed']();
      break;
    case 'Number':
      validator = Yup['number']();
      break;
    case 'Checkbox':
      validator = Yup['boolean']();
      break;
    case 'List':
    default:
    case 'Alpha':
      validator = Yup['string']();
  }

  Validation.forEach(validation => {
    let type, params;
    switch (validation) {
      case 'IsPostcode':
        type = 'matches';
        params = [/^[0-9]{4}(?:-[0-9]{3})?$/, locale.validation.zip];
        validator = validator[type](...params);
        break;
      case 'True':
        type = 'oneOf';
        params = [[true], locale.validation.required];
        validator = validator[type](...params);
        break;
      case '':
      case 'IsMandatory':
        type = 'required';
        params = [locale.validation.required];
        validator = validator[type](...params);
        break;
      case 'IsInteger':
        type = 'integer';
        params = [locale.validation.integer];
        validator = validator.nullable()[type](...params);
        break;
      case 'IsPositive':
        type = 'positive';
        params = [locale.validation.positive];
        validator = validator.nullable()[type](...params);
        break;
      case 'MaxLength(4)':
        type = 'max';
        params = [9999, locale.validation.maxlength4];
        validator = validator.nullable()[type](...params);
        break;
      case 'MaxLength(12)':
        type = 'max';
        params = [999999999999, locale.validation.maxlength12];
        validator = validator.nullable()[type](...params);
        break;
      case 'MaxLength(60)':
        type = 'max';
        params = [60, locale.validation.max60];
        validator = validator.nullable()[type](...params);
        break;
      case 'MaxLength(100)':
        type = 'max';
        params = [100, locale.validation.max100];
        validator = validator.nullable()[type](...params);
        break;
      default:
        break;
    }
  });

  if (visibility && visibility.length > 0) {
    visibility.forEach(vis => {
      if (vis.InputId === InputId) {
        vis.Rules[0].Expressions.forEach(expression => {
          let params = [locale.validation.required];
          validator = Yup['mixed']().when(expression.InputId, {
            is: expression.Value,
            then: Yup['string']()['required'](...params),
          });
        });
      }
    });
  }

  schema[InputId] = validator;
  return schema;
};

export const getValidations = (simulatorData, holder, nif) => {
  const rules = {};
  const initialValues = {};
  const visibility = simulatorData.Visibility || [];

  simulatorData.Policyholder.map(prop => {
    if (prop.Validation) {
      createYupSchema(rules, prop, visibility);
    }

    return false;
  });

  if (holder) {
    holder.map(prop => {
      initialValues[prop.InputId] = prop.Answer;

      if (prop.InputId === 'POLICYHOLDERID') {
        initialValues[prop.InputId] = nif;
      }

      if (prop.InputId === 'COUNTRY') {
        initialValues[prop.InputId] = 'PRT';
      }

      return false;
    });
  } else {
    initialValues['POLICYHOLDERID'] = nif;
    initialValues['COUNTRY'] = 'PRT';
  }

  // if(simulatorData.ProductCode === 'EVT')
  // {
  //   const Enddate = document.querySelector('input[name="ENDDATE"]')?.value;
  //   //simulatorData.Questions.find(q => q.QuestionCode === 'ENDDATE');
  //   const StartDate = document.querySelector('input[name="PERIODSTART"]')?.value;
  //   //simulatorData.Questions.find(q => q.QuestionCode === 'PERIODSTART');

  //   const startDateSeven = new Date(StartDate?.Answer);

  //   var maxEndDate = startDateSeven.setDate(startDateSeven.getDate() + 7);

  //   if(Enddate > maxEndDate)
  //     return false;
  // }

  // if(simulatorData.ProductCode === 'PFX')
  // {
  //   var enddate = document.querySelector('input[name="ENDDATE"]');
  //   var startdate;
  //   var oneYearFromNow = new Date();

  //   if(!document.querySelector('input[name="PERIODSTART"]'))
  //   {
  //     startdate = new Date();
  //   }
  //   else
  //   {
  //     startdate = new Date(document.querySelector('input[name="PERIODSTART"]')?.value);
  //   }

  //   if(startdate != null && enddate != null)
  //   {
  //     oneYearFromNow.setFullYear(startdate.getFullYear() + 1);
  //     const maxFormattedDate = oneYearFromNow.toISOString().split('T')[0];
  
  //     enddate.value = maxFormattedDate;
  //     return false;
  //   }
  // }

  simulatorData.Questions.map(question => {
    if (question.Attribute === 'List' && !question.Validation) {
      question.Validation = ['IsMandatory'];
    }
    if (question.Validation) {
      createYupSchema(rules, question, visibility);
    }
    if(question.Attribute === 'List')
    {
      if(question && question.Answers && question.Answers[0]) 
        initialValues[question.InputId] = question.Answers[0].AnswerCode;
    }

    if(question.Attribute === 'Date')
    {
      // Get the current date
      const currentDate = new Date();

      // Format the date as YYYY-MM-DD
      const formattedDate = currentDate.toISOString().split('T')[0];

      initialValues[question.InputId] = formattedDate;    
    }

    else
    {
      initialValues[question.InputId] = '';
    }
    return false;
  });

  if (simulatorData.Risks) {
    simulatorData.Risks.map(risk => {
      risk.Questions.map(question => {
        if (question.Attribute === 'List' && !question.Validation) {
          question.Validation = ['IsMandatory'];
        }
        if (question.Validation) {
          createYupSchema(rules, question, visibility);
        }

        if(question.Attribute === 'List')
        {
          if(question && question.Answers && question.Answers[0]) 
            initialValues[question.InputId] = question.Answers[0].AnswerCode;
        }
        else
        {
          initialValues[question.InputId] = '';
        }
        return false;
      });

      risk.Covers.map(cover => {
        cover.Items.map(item => {
          item.Questions.map(question => {
            if (question.Attribute === 'List' && !question.Validation) {
              question.Validation = ['IsMandatory'];
            }
            if (question.Validation) {
              createYupSchema(rules, question, visibility);
            }
            if(question.Attribute === 'List')
            {
              if(question && question.Answers && question.Answers[0]) 
                initialValues[question.InputId] = question.Answers[0].AnswerCode;
            }
            else
            {
              initialValues[question.InputId] = '';
            }
            return false;
          });
          return false;
        });
        cover.Questions.map(question => {
          if (question.Attribute === 'List' && !question.Validation) {
            question.Validation = ['IsMandatory'];
          }
          if (question.Validation) {
            createYupSchema(rules, question, visibility);
          }
          if(question.Attribute === 'List')
          {
            if(question && question.Answers && question.Answers[0]) 
              initialValues[question.InputId] = question.Answers[0].AnswerCode;
          }
          else
          {
            initialValues[question.InputId] = '';
          }

          return false;
        });
        return false;
      });
      return false;
    });
  }

  createYupSchema(
    rules,
    {
      InputId: 'acceptance',
      Attribute: 'Checkbox',
      Validation: ['True'],
    },
    visibility
  );
  initialValues['acceptance'] = false;

  const validationSchema = Yup.object().shape(rules);
  return {validationSchema, initialValues};
};

export const isEmpty = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const getPreviousValues = answers => {
  const initialValues = {};

  answers.Policyholder.map(prop => {
    initialValues[prop.InputId] = prop.Answer;
    return false;
  });

  if (answers.Events[0].Transaction.PolicyAnswers) {
    answers.Events[0].Transaction.PolicyAnswers.map(prop => {
      initialValues[prop.InputId] = prop.Answer;
      return false;
    });
  }

  if (answers.Events[0].Transaction.PolicyRisks[0].PolicyAnswers) {
    answers.Events[0].Transaction.PolicyRisks[0].PolicyAnswers.map(prop => {
      initialValues[prop.InputId] = prop.Answer;
      return false;
    });
  }

  if (answers.Events[0].Transaction.PolicyRisks[0].PolicyCovers) {
    if (answers.Events[0].Transaction.PolicyRisks[0].PolicyCovers) {
      answers.Events[0].Transaction.PolicyRisks[0].PolicyCovers.map(cover => {
        if (cover.PolicyAnswers) {
          cover.PolicyAnswers.map(prop => {
            initialValues[prop.InputId] = prop.Answer;
            return false;
          });
        }

        if (cover.PolicyItems) {
          cover.PolicyItems.map(item => {
            item.PolicyAnswers.map(prop => {
              initialValues[prop.InputId] = prop.Answer;
              return false;
            });
            return false;
          });
        }
        return false;
      });
    }
  }

  initialValues['acceptance'] = false;

  return initialValues;
};

export const getVisibility = (fieldInput, visibilityRules, values) => {
  const fieldRules = visibilityRules.filter(
    rule => rule.InputId === fieldInput
  );

  let shoudBeVisible;
  if (fieldRules && fieldRules[0]) {
    shoudBeVisible = fieldRules[0].Rules[0].Expressions.every(expression => {
      switch (expression.Operator) {
        case '<>':
          return (
            values[expression.InputId] !== expression.Value &&
            values[expression.InputId] !== ''
          );
        case '>':
          return (
            values[expression.InputId] * 1 > expression.Value * 1 &&
            values[expression.InputId] !== ''
          );
        case '>=':
          return (
            values[expression.InputId] * 1 >= expression.Value * 1 &&
            values[expression.InputId] !== ''
          );
        case '<':
          return (
            values[expression.InputId] * 1 < expression.Value * 1 &&
            values[expression.InputId] !== ''
          );
        case '<=':
          return (
            values[expression.InputId] * 1 <= expression.Value * 1 &&
            values[expression.InputId] !== ''
          );
        default:
        case '=':
          return (
            values[expression.InputId] === expression.Value &&
            values[expression.InputId] !== ''
          );
      }
    });
  } else {
    shoudBeVisible = true;
  }

  return !shoudBeVisible;
};
